import React from 'react';
import '../Styles/Home.css'; // Make sure this path is correct
function Home() {
    return (
        <div className="home-background">

            <header id="home-intro">
                <h1>AstraWeave Solutions LLC</h1>
                <p>Welcome to AstraWeave Solutions LLC - Your partner in navigating the complexities of technology and innovation.</p>
            </header>


            <FeaturedProducts />

        </div>
    );
}

// function Services() {
//     return (
//         <section id="services">
//             <h2>Our Services</h2>
//             <div className="services-list">
//                 {/* Service articles */}
//             </div>
//         </section>
//     );
// }


function FeaturedProducts() {
    return (
        <section id="featured-products">
            <h2>Featured Products</h2>
            <div className="products-list">
                <article>
                    <h3>IT Audits</h3>
                    <p>Ensure your IT infrastructure is robust, secure, and efficiently driving your business goals forward with our comprehensive IT audit services.</p>
                </article>
                <article>
                    <h3>Software Project Consulting</h3>
                    <p>From conception to deployment, leverage our expertise in software development to navigate complex project challenges and achieve successful outcomes.</p>
                </article>
                <article>
                    <h3>Cybersecurity Risk Assessments</h3>
                    <p>Identify, assess, and mitigate cybersecurity risks to protect your assets and ensure business continuity with our in-depth risk assessment services.</p>
                </article>
            </div>
        </section>
    );
}



export default Home;
