import React, { useState } from 'react';
import '../Styles/Services.css';
import Header from "./Header";

function ServiceItem({ title, description, moreContent }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <article className="service-item" onClick={() => setIsOpen(!isOpen)}>
            <h3>{title}</h3>
            <p>{description}</p>
            {isOpen && <div className="more-content">{moreContent}</div>}
        </article>
    );
}

function WhyServices() {
    const [expandedBox, setExpandedBox] = useState(null);

    const toggleExpand = (boxId) => {
        setExpandedBox(expandedBox === boxId ? null : boxId);
    };

    const boxes = [
        {
            id: 1,
            title: "Are you a small business owner looking for specific software solutions?",
            details: "Discover how our custom software solutions can drive your business forward, tailored precisely to your unique challenges and opportunities."
        },
        {
            id: 2,
            title: "Looking to automate and streamline your operations?",
            details: "Learn about our automation services that can enhance efficiency, reduce costs, and enable your team to focus on high-value activities."
        },
        {
            id: 3,
            title: "Have IT infrastructure you need support maintaining or setting up?",
            details: "Find out how our IT support services can ensure your infrastructure is robust, secure, and scalable, supporting your business growth every step of the way."
        },
        {
            id: 4,
            title: "Cybersecurity concerns keeping you up at night?",
            details: "Explore our cybersecurity services designed to protect your business from threats, ensuring the safety and integrity of your digital assets."
        }
    ];

    return (
        <section id="why-services">
            <h2>Why Our Services?</h2>
            <div className="flowchart">
                {boxes.map(box => (
                    <div key={box.id} className="box" onClick={() => toggleExpand(box.id)}>
                        <h3>{box.title}</h3>
                        {expandedBox === box.id && <p>{box.details}</p>}
                    </div>
                ))}
            </div>
        </section>
    );
}


function Services() {
    return (
        <div className="services-container">
            <Header/>
            <section id="services-section">
                <h2>Our Services</h2>
                <div className="services-list">
                    <ServiceItem
                        title="Custom Consulting"
                        description="We partner with small business owners to architect software solutions tailored to their unique challenges, from server-side development to innovative applications and AI-driven insights."
                        moreContent="Detailed consulting services include but are not limited to: project management, software architecture, cloud solutions, and digital transformation strategies."
                    />
                    <ServiceItem
                        title="Software Development"
                        description="Our team crafts custom software solutions, ranging from efficient backend systems to user-centric applications, designed specifically for your business needs."
                        moreContent="We specialize in full-stack development, including web applications, mobile apps, and enterprise software solutions."
                    />
                    <ServiceItem
                        title="Cybersecurity"
                        description="Protect your digital assets and ensure your business's resilience against cyber threats with our comprehensive cybersecurity services."
                        moreContent="From vulnerability assessments and penetration testing to incident response and recovery planning, our team is equipped to fortify your cybersecurity posture."
                    />
                    <ServiceItem
                        title="IT & Networking"
                        description="Ensure your IT infrastructure is robust, efficient, and scalable with our IT and networking solutions."
                        moreContent="Our services include network design and implementation, ongoing IT support, and infrastructure management, all tailored to meet your business's specific needs."
                    />
                </div>
            </section>
            <WhyServices />
        </div>
    );
}

export default Services;
