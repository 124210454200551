import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Header.css';

function Header() {
    return (
        <header className="home-nav">
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;