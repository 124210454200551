import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import Header from './Header';
import companyLogo from '../static/images/logo.jpeg';
import '../Styles/About.css';

function AboutUs() {
    return (
        <>
            <Header />

            <section className="about-us-container">
                <Container>
                    <Row className="align-items-center mb-5">
                        <Col xs={12}>
                            <Card className="about-us-card">
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <Image src={companyLogo} alt="AstraWeave Solutions LLC Logo" className="company-logo mr-3" />
                                        <div>
                                            <Card.Title><h2>About Us</h2></Card.Title>
                                            <Card.Text>
                                                <p>AstraWeave Solutions LLC is a recently founded startup fueled by a passion for empowering small businesses with custom technology solutions. Our team of innovators and technologists believes that software holds the key to solving real-world problems, and we're dedicated to helping you reach your full potential.</p>
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Our Mission */}
                    <Card className="section-card">
                        <Card.Body>
                            <Card.Title><h3>Our Mission</h3></Card.Title>
                            <Card.Text>
                                {"Our Mission is to provide businesses with the tools and resources they need to succeed in the digital age. We are committed to delivering high-quality, cost-effective solutions that help our clients grow and thrive."}
                            </Card.Text>
                        </Card.Body>
                    </Card> {/* Why Choose Us? */}
                    <Card className="section-card my-4">
                        <Card.Body>
                            <Card.Title><h3>Why Choose Us?</h3></Card.Title>
                            <Card.Text>
                                Choosing AstraWeave Solutions LLC means opting for a partner who understands the importance of innovation and customization in driving business growth. Our commitment to providing personalized and effective solutions sets us apart, ensuring your business not only meets but exceeds its digital transformation goals. Choosing us means that
                                you can have time focusing on your business while we take care of the technology. We know that your time is valuable, and we are here to help you make the most of it.
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    {/* Our Values */}
                    <Card className="section-card my-4">
                        <Card.Body>
                            <Card.Title><h3>Our Values</h3></Card.Title>
                            <Card.Text>
                                Integrity, Innovation, and Impact are the core values that guide our operations and service delivery. We believe in doing the right thing for our clients, pushing the boundaries of technology to create novel solutions, and making a significant positive impact on every business we work with.
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    {/* Vision for the Future */}
                    <Card className="section-card my-4">
                        <Card.Body>
                            <Card.Title><h3>Vision for the Future</h3></Card.Title>
                            <Card.Text>
                                Our vision is to be at the forefront of facilitating business transformations through advanced technology. We aim to expand our services globally, continuously innovating to stay ahead of industry trends and enabling our clients to achieve unparalleled success in an ever-evolving digital landscape.
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </Container>
            </section>
        </>
    );
}

export default AboutUs;