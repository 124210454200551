import React from 'react';
import terms from '../static/toc.json';
import '../Styles/TAC.css';
const TermsAndConditions = () => {
    return (
        <div className="terms-conditions-page">
            <h1>{terms.title}</h1>
            <p>Last updated: {terms.lastUpdated}</p>

            <section>
                <h2>{terms.introduction.welcome}</h2>
                <p>{terms.introduction.description}</p>
                <p>{terms.introduction.acceptanceClause}</p>
            </section>

            <section>
                <h2>License</h2>
                <p>{terms.license.ownershipStatement}</p>
                <ul>
                    {terms.license.restrictions.map((restriction, index) => (
                        <li key={index}>{restriction}</li>
                    ))}
                </ul>
            </section>

            <section>
                <h2>User Comments</h2>
                <p>{terms.userComments.policy}</p>
                <p>{terms.userComments.liabilityStatement}</p>
                <p>{terms.userComments.rights}</p>
            </section>

            <section>
                <h2>Disclaimer</h2>
                {terms.disclaimer.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </section>


            <section>
                <h2>Contact Information</h2>
                <p>{terms.contactInformation.companyName}</p>
                <p>{terms.contactInformation.address}</p>
                <p>{terms.contactInformation.email}</p>
            </section>
        </div>
    );
}

export default TermsAndConditions;
