import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; // Corrected import paths
import Services from './pages/Services';
import Contact from './pages/Contact';
import Carrers from "./pages/Carrers";
import AboutUs from './pages/About'; // Ensure the filename matches exactly
import './App.css';
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import SiteMap from "./pages/sitemap.xml";
import TermsAndConditions from "./pages/tac";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
    return (
        <Router>
            <div className="main-content">
            <Header/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/about" element={<AboutUs/>}/>
                    <Route path="/careers" element={<Carrers/>}/>
                    <Route path="/sitemap.xml" element={<SiteMap/>}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />}/>


                </Routes>
                <Footer />

            </div>
        </Router>

);
}

export default App;
