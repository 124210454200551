import React from 'react';
import jobListings from '../static/jobs.json';
import '../../src/Styles/Carrers.css';

function Careers() {
    return (
        <div className="careers container">
            <h1 className="display-4 font-weight-bold">Careers at AstraWeave Solutions LLC</h1>
            <p className="lead">We are a new startup always looking for talented individuals to join our team. Check out our open positions:</p>

            <div className="job-listings">
                {jobListings.map((job, index) => (
                    <div key={index} className="job-card">
                        <h2 className="job-title font-weight-bold">{job.title}</h2>
                        <p className="job-description">{job.description}</p>
                        <p className="job-pay"><strong>Pay:</strong> {job.pay}</p>
                        <p className="job-skills"><strong>Skills:</strong> {job.skills.join(', ')}</p>
                        <p className="job-dates"><strong>Listing Date:</strong> {job.listingDate} | <strong>Close Date:</strong> {job.closeDate}</p>
                    </div>
                ))}
            </div>
            <p className="lead">Interested in applying? Email your resume and cover letter to</p>
          <a href="mailto:hr@astraweave.com" className="font-weight-bold">hr@astraweave.com</a> to apply.
        </div>
    );
}

export default Careers;
