import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function ContactUs() {
    const formContainerStyle = {
        backgroundColor: '#121212',
        padding: '2rem',
        borderRadius: '0.5rem',
        marginTop: '5rem',
        marginBottom: '5rem'
    };

    const formStyle = {
        color: '#E3E3E3'
    };

    const formControlStyle = {
        backgroundColor: '#1E1E2F',
        borderColor: '#252545',
        color: '#E3E3E3',
        marginBottom: '1rem'
    };

    const formLabelStyle = {
        color: '#E3E3E3'
    };

    const buttonStyle = {
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50', // Match border color to background
        marginTop: '1rem',
        color: '#121212',
        borderWidth: '0' // Remove the default border
    };

    const orEmailUsStyle = {
        color: '#E3E3E3',
        textAlign: 'center',
        marginTop: '2rem',
        '& a:focus': {  // Target the 'a' link when it's in focus
            outline: 'none',   // Remove the standard outline
            boxShadow: '0 0 0 0.2rem rgba(76, 175, 80, 0.5)' // Subtle custom focus indicator
        }
    };


    return (
        <>
            <Container className="services-container">
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="contact-form-container" style={formContainerStyle}>
                            <h2 className="text-center" style={{color: '#FFC107'}}>Contact Us</h2>
                            <Form action="https://public.herotofu.com/v1/00ffc160-e0f3-11ee-aeb9-f5197559ca8c"
                                  method="post" acceptCharset="UTF-8" style={formStyle}>
                                <Form.Group>
                                    <Form.Label style={formLabelStyle}>Your Name</Form.Label>
                                    <Form.Control type="text" name="Name" required style={formControlStyle}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label style={formLabelStyle}>Your Email</Form.Label>
                                    <Form.Control type="email" name="Email" required style={formControlStyle}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label style={formLabelStyle}>Interested Service </Form.Label>
                                    <Form.Control as="select" name="Service" defaultValue="" style={formControlStyle}>
                                        <option value="" disabled>Select a Service</option>
                                        <option value="Custom Software Development">Custom Software Development</option>
                                        <option value="Cybersecurity">Cybersecurity</option>
                                        <option value="IT & Networking">IT & Networking</option>
                                        <option value="AI & Machine Learning">AI & Machine Learning</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label style={formLabelStyle}>Message</Form.Label>
                                    <Form.Control as="textarea" name="Message" rows={3} required
                                                  style={formControlStyle}/>
                                </Form.Group>
                                <div className="text-center">
                                    <Button variant="primary" type="submit" style={buttonStyle}>
                                        Send
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        <div style={orEmailUsStyle}>
                            <p>or email us at <a href="mailto:info@astraweave.com">info@astraweave.com</a></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactUs;
