import React from 'react';
import privacyPolicyData from '../static/pp.json'; // Adjust the path as necessary
import '../Styles/pp.css';
const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>{privacyPolicyData.title}</h1>
            <p>Effective Date: {privacyPolicyData.effectiveDate}</p>

            {/* Introduction Section */}
            <section>
                <h2>Introduction</h2>
                {privacyPolicyData.introduction.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </section>

            {/* Data Collection by Cloudflare Section */}
            <section>
                <h2>{privacyPolicyData.dataCollectionCloudflare.title}</h2>
                {privacyPolicyData.dataCollectionCloudflare.description.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
                <p>Cloudflare's Privacy Policy:
                    <a style={{background:"transparent"}} href="https://www.cloudflare.com/privacypolicy/" target="_blank"
                       rel="noopener noreferrer">View Here</a>

            </p>
        </section>

    {/* No Personal Data Collection Section */
    }
    <section>
    <h2>{privacyPolicyData.noPersonalDataCollection.title}</h2>
                <p>{privacyPolicyData.noPersonalDataCollection.description}</p>
            </section>

            {/* Security Section */}
            <section>
                <h2>{privacyPolicyData.security.title}</h2>
                <p>{privacyPolicyData.security.description}</p>
            </section>

            {/* Third-Party Links Section */}
            <section>
                <h2>{privacyPolicyData.thirdPartyLinks.title}</h2>
                <p>{privacyPolicyData.thirdPartyLinks.description}</p>
            </section>

            {/* Changes to This Privacy Policy Section */}
            <section>
                <h2>{privacyPolicyData.changesToThisPrivacyPolicy.title}</h2>
                <p>{privacyPolicyData.changesToThisPrivacyPolicy.description}</p>
            </section>

            {/* Contact Us Section */}
            <section>
                <h2>{privacyPolicyData.contactUs.title}</h2>
                <p>{privacyPolicyData.contactUs.description}</p>
            </section>
        </div>
    );
}

export default PrivacyPolicy;
