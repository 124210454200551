import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
export default function Footer() {
    return (
        <footer className="footer mt-auto py-3 bg-dark text-white">
            <div className="container">
                <div className="address">
                    <p>AstraWeave Solutions LLC</p>
                    <p>1942 Broadway St. STE 314C</p>
                    <p>Boulder, CO 80302</p>
                    <p>Email:<a className="email-link" href="mailto:info@astraweave.com">info@astraweave.com</a></p>
                </div>
                <div className="links">
                    <ul>
                        <li><Link to="/services">Managed IT Services</Link></li>
                        <li><Link to="/services">Cybersecurity</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                        <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}